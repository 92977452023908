//@Packages
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AppConfig {

    // public ApiUrl: string = "http://localhost/avasargold/avsar_backend/public/api/";
    // public ImageUrl: string = "http://localhost/avasargold/avsar_backend/public/";


    // Live URL
    // http://avsargold.phpfalks.com
    // index.html => base href => "/"
    // public ApiUrl: string = "https://admin.avsarjewellers.com/avsar_backend/public/api/";
    // public ImageUrl: string = "https://admin.avsarjewellers.com/avsar_backend/public/";

    // public ApiUrl: string = "https://teqmeer.com/avsar_backend/public/api/";
    //    public ImageUrl: string = "https://admin.avsarjewellers.com/avsar_backend/public/";


    //// QA URL
    //  public ApiUrl: string = "https://qaavsargoldapi.softcube.co.in/api/";
    //  public ImageUrl: string = "https://avsar.s3.ap-south-1.amazonaws.com/avsar_dev/";
    //  public ImageUrl: string = "http://127.0.0.1:8000/public/";

    //// QA URL
    //// http://43.252.197.237/avsargold_qa/avasargold/avsar
    //// index.html => base href => "/avsargold_qa/avasargold/avsar/"

    //  public ApiUrl: string = "http://43.252.197.237/avsargold_qa/avasargold/avsar_backend/public/api/";
    //  public ImageUrl: string = "http://43.252.197.237/avsargold_qa/avasargold/avsar_backend/public/";

    // public ApiUrl: string = "http://127.0.0.1:8000/api/";
    // public ImageUrl: string = "http://127.0.0.1:7000/public/";


    // public ApiUrl: string = "http://127.0.0.1:8000/api/"; //my local
    public ApiUrl: string = "https://adminapi.avsarjewellers.com/api/";
    // public ImageUrl: string = "http://192.168.1.151:3000/public/";

    // public ApiUrl: string = "https://stageavsargoldapi.softcube.co.in/api/";
    public ImageUrl: string = "https://avsar.s3.ap-south-1.amazonaws.com/avsar_stage/"; //my local

    public getAuthHeaders() {
        let httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        };
        return httpOptions;
    }

    public getHeaders() {
        let httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
            })
        };
        return httpOptions;
    }
}
